import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Chatbot.css';

const API_KEY = "sk-proj-ZRCBNEN52W37nxeq7EAvT3BlbkFJxAWEjo7Y6TDZfKU2ECEP";

const sites = [
  'https://www.digikey.com/en/products',
  'https://www.mouser.com/new/connectors/n-5g3y',
  'https://www.newark.com/browse-for-products',
];

const fetchFromSite = async (url, message) => {
  try {
    const response = await axios.get(`http://localhost:5000/fetch-url?url=${encodeURIComponent(url)}`);
    const data = response.data;
    // Here you would process the data to extract relevant information
    if (data && data.includes(message)) {
      return data; // or extract the relevant snippet from the data
    }
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
  }
  return null;
};

const checkSitesForAnswer = async (message) => {
  for (const site of sites) {
    const answer = await fetchFromSite(site, message);
    if (answer) {
      return answer;
    }
  }
  return null;
};

const fetchFromRapidAPI = async (message) => {
  try {
    const response = await axios.get('https://example-rapidapi-endpoint', {
      params: { query: message },
      headers: {
        'X-RapidAPI-Host': 'example-rapidapi-host',
        'X-RapidAPI-Key': 'your-rapidapi-key'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from RapidAPI:', error);
    return null;
  }
};

const Chatbot = () => {
  const [messages, setMessages] = useState([{ sender: 'bot', text: "How can I help you today?" }]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const sendMessage = async (message) => {
    if (!message) return;

    const newMessage = { sender: 'user', text: message };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');

    console.log('User message:', message);

    const siteAnswer = await checkSitesForAnswer(message);
    if (siteAnswer) {
      console.log('Found answer from site:', siteAnswer);
      const botMessage = { sender: 'bot', text: siteAnswer };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } else {
      const apiAnswer = await fetchFromRapidAPI(message);
      if (apiAnswer) {
        console.log('Found answer from RapidAPI:', apiAnswer);
        const botMessage = { sender: 'bot', text: apiAnswer };
        setMessages(prevMessages => [...prevMessages, botMessage]);
      } else {
        console.log('No answer found from sites or RapidAPI, querying OpenAI...');
        try {
          const response = await axios.post('https://api.openai.com/v1/chat/completions', {
            model: "gpt-3.5-turbo",
            messages: [
              { role: "user", content: message }
            ],
            max_tokens: 350
          }, {
            headers: {
              'Authorization': `Bearer ${API_KEY}`,
              'Content-Type': 'application/json'
            }
          });

          const botMessage = { sender: 'bot', text: response.data.choices[0].message.content.trim() };
          console.log('OpenAI response:', botMessage.text);
          setMessages(prevMessages => [...prevMessages, botMessage]);
        } catch (error) {
          const errorMessage = { sender: 'bot', text: `Error: ${error.message}` };
          setMessages(prevMessages => [...prevMessages, errorMessage]);
          console.error('Error fetching response:', error);
        }
      }
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage(input);
    }
  };

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="chatbot-container">
      <button className="chatbot-toggle" onClick={toggleChatbot}>
        {isOpen ? 'Close Chat' : 'Open Chat'}
      </button>
      {isOpen && (
        <div className="chatbot">
          <div className="chat-window">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="input-area">
            <input 
              type="text" 
              value={input} 
              onChange={(e) => setInput(e.target.value)} 
              placeholder="Type your message..." 
              onKeyPress={handleInputKeyPress}
            />
            <button onClick={() => sendMessage(input)}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
