// src/pages/About.js
import React, { useEffect, useState } from "react";
import content from "../data/data.json";
import "../styles/About.css";
import Image1 from "../assets/4.png";
import Image2 from "../assets/5.png";
import Image3 from "../assets/6.png";
import Image4 from "../assets/7.png";
import Image5 from "../assets/8.png";
import Image6 from "../assets/9.png";
import Image7 from "../assets/10.png";
function About() {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(content.about);
  }, []);

  return (
    <div className="about">
      <section className="about-hero"></section>
      <section className="main-data">
        <div className="section-content">
          <div className="text">
            At <span className="brand">My eDMAC</span>, we are dedicated to
            revolutionizing the way electronic industry companies manage and
            improve their engineering processes. With a rich history spanning
            over two decades, we have established ourselves as a trusted leader
            in providing robust B2B services, particularly tailored for the
            unique challenges of this sector.
          </div>
          <div className="image">
            <img src={Image1} alt="Description 1" />
          </div>
        </div>

        <div className="section-content">
          <div className="text">
            Our expertise encompasses a wide range of specialized services aimed
            at enhancing your company's product lifecycle, from New Product
            Introduction (NPI) to complex component engineering tasks.
            <br /> We pride ourselves on our ability to offer comprehensive
            support in BOM (Bill Of Materials) management, Configuration
            Management, and standard component engineering activities like part
            number additions, component information retrieval, and
            cost-effective sourcing solutions.
          </div>
          <div className="image">
            <img src={Image2} alt="Description 2" />
          </div>
        </div>

        <div className="section-content">
          <div className="text">
            We understand the importance of staying ahead in compliance and
            regulatory standards.
            <br /> Therefore, we handle all aspects of Environmental Compliance
            Directives such as RoHS, REACH, and Conflict Minerals, ensuring your
            products meet the latest industry standards.
          </div>
          <div className="image">
            <img src={Image3} alt="Description 3" />
          </div>
        </div>

        <div className="section-content">
          <div className="text">
            For startups and small companies lacking robust data management
            systems like ERP or PLM/PDM, <span className="brand">My eDMAC</span>{" "}
            provides value-added services designed to enhance data handling,
            replacing outdated systems like Excel with advanced data management
            tools. <br />
            These include embedding engineering processes, optimizing component
            management, and facilitating prototype development.
          </div>
          <div className="image">
            <img src={Image4} alt="Description 3" />
          </div>
        </div>

        <div className="section-content">
          <div className="text">
            Recognizing the potential of emerging businesses,{" "}
            <span className="brand">My eDMAC</span> also caters to startups and
            small companies without extensive data management systems.
            <br /> Our value-added services are designed to assist these
            companies in transitioning from basic tools like Excel to more
            sophisticated data management systems, enhancing overall efficiency
            and data reliability.
          </div>
          <div className="image">
            <img src={Image5} alt="Description 3" />
          </div>
        </div>

        <div className="section-content">
          <div className="text">
            Our team, enriched with experience from diverse departments like
            R&D, operations, quality assurance, and purchasing, is committed to
            delivering excellence and innovation in every project.
            <br /> At <span className="brand">My eDMAC</span>, we are more than
            just a service provider.
            <br /> we are your strategic partner in navigating the complexities
            of the electronic industry, helping you achieve operational
            excellence and market success.
          </div>
          <div className="image">
            <img src={Image6} alt="Description 3" />
          </div>
        </div>
        <div className="section-content">
          <div className="text">
            Explore the comprehensive range of services offered by <span className="brand">My eDMAC</span>,
            designed to empower your electronic industry ventures.<br /> Our services
            page details how we can assist you in enhancing every aspect of your
            engineering and component engineering processes.<br /> From pioneering New
            Product Introduction (NPI) strategies to meticulous management of
            Bills of Materials and Configuration, our offerings are tailored to
            elevate your operations.<br /> Dive into each service to understand how <span className="brand">My eDMAC</span>
            can help streamline your workflows, ensure environmental
            compliance, and leverage advanced data management solutions to
            replace outdated systems.<br /> Let’s elevate your electronic industry
            capabilities together
          </div>
          <div className="image">
            <img src={Image7} alt="Description 3" />
          </div>
        </div>
      </section>
      <div className="container" style={{ display: "none" }}>
        <section className="about-content">
          <h1>{data.title}</h1>
          <p>{data.description}</p>
          {data.content &&
            data.content.map((item, index) => (
              <div key={index} className="about-section">
                <h2>{item.heading}</h2>
                <p>{item.text}</p>
              </div>
            ))}
        </section>
      </div>
    </div>
  );
}

export default About;
